<template>
    <headers></headers>
    <div class="top-gap"></div>
    <div class="pc">
        <div class="pc-stack">
            <div>
                <div class="titles">
                    <b>My team and community</b>
                    <p>{{ $t('user.desc') }}</p>
                </div>
                <div class="my-link">
                    <b>{{ $t('user.myLink') }}</b>
                    <div>
                        <div class="link">{{  inviteLink }}</div>
                        <div class="imgs" @click="copy()">
                            <img src="../../static/images/copy.png" >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- pc-个人信息 -->
        <div class="pc-info">
            <div class="record">
                <div>
                    <button>
                        {{ $t('user.info') }}
                    </button>
                </div>
            </div>
            <div class="module-info">
                <img :src="require('../../static/images/grade-' + getGrade(info.grade, mStore.memberInfo.uid) + '.png')" >
                <div>
                    <span>{{ $t('user.joinTime') }}:  {{Util.showTime(info.regTime)}}</span>
                    <div class="member">
                        <span>{{ $t('user.memberTeam') }}: {{Util.numFormatBase(info.teamMember)}}</span>
                        <span>{{ $t('user.memberDirect') }}: {{Util.numFormatBase(info.directMember)}}</span>
                        <span>{{ $t('user.teamLp') }}: {{Util.showAmount(info.teamStake)}} USDT</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- pc-我的团队 -->
        <div class="pc-list">
            <!-- title和地址 -->
            <div class="address-set">
                <button>
                    {{ $t('user.myTeam') }}
                </button>
                <div>
                    {{ $t('user.cur') }}
                    <div @click="showAddressMore=!showAddressMore" class="get-address" v-if="teams.addressList.length">{{ teams.addressList[teams.addressList.length-1].showAddress }}</div>
                    <div @click="showAddressMore=!showAddressMore" class="get-address" v-else>...</div>
                    <img @click="showAddressMore=!showAddressMore" class="switch" src="../../static/images/switch.png">
                    <div class="dia" v-if="showAddressMore&&teams.addressList.length">
                        <div @click="getSubTeamList(item.address)" :style="{'margin-left': index * 10 + 'px'}" :class="index==teams.addressList.length-1?'end':''" v-for="(item,index) in teams.addressList" :key="index">
                            <img v-if="item.showAddress!='...'" src="../../static/images/arrow.png" >
                            <span>{{ item.showAddress }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 列表 -->
            <div class="lists">
                <div class="list">
                    <div v-for="(item,index) in teams.list" :key="index">
                        <img class="grade-icon" :src="require('../../static/images/grade-' + getGrade(item.grade, item.uid) + '.png')" >
                        <div>
                            <div class="date">
                                <div>
                                    <span>{{ item.address }}</span>
                                    <span>{{ $t('user.joinTime') }}:  {{ item.regTime }}</span>
                                </div>
                                <button @click="getSubTeamList(item.address)">{{ $t('user.lookDown') }}
                                    <img src="../../static/images/more.png" >
                                </button>
                            </div>
                            <div class="member">
                                <span>{{ $t('user.memberDirect') }}: {{item.share}}</span>
                                <span>{{ $t('user.memberTeam') }}: {{item.team}}</span>
                                <span>{{ $t('user.personage') }}: {{item.stakeRemainTotal}} USDT</span>
                                <span>{{ $t('user.teamLp') }}: {{item.teamStakeTotal}} USDT</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more" v-if="teams.page < teams.totalPage" @click="getNextTeamList">{{ $t('lp.more') }}</div>
                <no-data v-if="!teams.totalRecord"></no-data>
            </div>
        </div>
    </div>
    <!-- 手机端 -->
    <div class="mobile">
        <!-- 等级图标 -->
        <div class="user-grade">
            <img :src="require('../../static/images/grade-' + getGrade(info.grade, mStore.memberInfo.uid) + '.png')" >
        </div>
        <!-- 个人信息 -->
        <div class="mobile-bg m-info">
            <b>{{ $t('user.info') }}</b>
            <div>{{ $t('user.joinTime') }}: <span>{{Util.showTime(info.regTime)}}</span></div>
            <div>{{ $t('user.memberTeam') }}: <span>{{Util.numFormatBase(info.teamMember)}}</span></div>
            <div>{{ $t('user.memberDirect') }}: <span>{{Util.numFormatBase(info.directMember)}}</span></div>
            <div>{{ $t('user.teamLp') }}: <span>{{Util.showAmount(info.teamStake)}} USDT</span></div>
        </div>
        <!-- 邀请链接&&团队 -->
        <div class="mobile-bg m-link">
            <b>My teme and community</b>
            <div>{{ $t('user.desc') }}</div>
            <div class="link">{{ $t('user.myLink') }}</div>
            <!-- 邀请链接 -->
            <div class="link-copy">
                <div class="text">{{  inviteLink }}</div>
                <div class="imgs" @click="copy()"><img src="../../static/images/copy.png"></div>
            </div>
            <!-- 地址选择 -->
            <div class="m-address-set">
                <div class="title">
                    {{ $t('user.myTeam') }}
                </div>
                <div>
                    {{ $t('user.cur') }}
                    <div @click="showAddressMore=!showAddressMore" class="get-address" v-if="teams.addressList.length">{{ teams.addressList[teams.addressList.length-1].showAddress }}</div>
                    <div @click="showAddressMore=!showAddressMore" class="get-address" v-else>...</div>
                    <img @click="showAddressMore=!showAddressMore" class="switch" src="../../static/images/switch.png">
                    <div class="dia" v-if="showAddressMore&&teams.addressList.length">
                        <div @click="getSubTeamList(item.address)" :style="{'margin-left': index * 10 + 'px'}" :class="index==teams.addressList.length-1?'end':''" v-for="(item,index) in teams.addressList" :key="index">
                            <img v-if="item.showAddress!='...'" src="../../static/images/arrow.png" >
                            <span>{{ item.showAddress }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 列表 -->
            <div class="m-list" v-for="(item,index) in teams.list" :key="index">
                <div class="date-lay">
                    <img class="grade-icon" :src="require('../../static/images/grade-' + getGrade(item.grade, item.uid) + '.png')" >
                    <div class="date">
                        <div>{{ item.showAddress }} <span @click="copyAddress(item.address)">{{ $t('user.copy') }}</span></div>
                        <span>{{ $t('user.joinTime') }}: {{ item.regTime }}</span>
                    </div>
                </div>
                <div class="row">{{ $t('user.personage') }} <span>{{item.stakeRemainTotal}} USDT</span></div>
                <div class="row">{{ $t('user.memberDirect') }}<span> {{item.share}} </span></div>
                <div class="row">{{ $t('user.memberTeam') }} <span>{{item.team}}</span></div>
                <div class="row">{{ $t('user.teamLp') }} <span>{{item.teamStakeTotal}} USDT</span></div>
                <button @click="getSubTeamList(item.address)">{{ $t('user.lookDown') }}
                    <img src="../../static/images/more.png">
                </button>
            </div>
            <div class="more" v-if="teams.page < teams.totalPage" @click="getNextTeamList">{{ $t('lp.more') }}</div>
            <no-data v-if="!teams.totalRecord"></no-data>
        </div>
        <div style="height: 40px;"></div>
    </div>

    <bases></bases>
</template>
<script setup>
import headers from '@/components/header'
import bases from '@/components/base'
import { ref, reactive, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';

import { queryInfo, queryTeamList } from '@/api/user'

import { memberStore } from "@/store/member"
import { walletStore } from "@/store/wallet"
import { webStore } from "@/store/web"
import i18n from "@/i18n";
import { ElMessage } from "element-plus";
import { call, getContractAddress, send } from "@/contract/web3-util";
import Util from "@/utils/common-util";
import Web3 from "web3";
import { end, start } from "@/api/loading";

const mStore = memberStore()
const wStore = walletStore()
const wbStore = webStore()

const router = useRouter();

const info = reactive({
    grade: 0,
    regTime: '',
    directMember: 0,
    teamMember: 0,
    teamStake: 0
})

const teams = reactive({
    addressList: [],
    curAddress: '',
    page: 1,
    pageSize: 0,
    totalPage: 0,
    totalRecord: 0,
    list: [],
})

const list = reactive([
    { grade: 1, address: '0x12321312', regTime: '2023-09-02 12:00:00', member: '100' },
    { grade: 2, address: '0x12321312', regTime: '2023-09-02 12:00:00', member: '100' },
    { grade: -1, address: '0x12321312', regTime: '2023-09-02 12:00:00', member: '100' }
])

const inviteLink = computed(() => {
    if (wStore.curAccount && mStore.memberInfo.uid > 0) {
        return process.env.VUE_APP_BASE_URL + '/#/?invite=' + wStore.curAccount
    }

    return ''
})

const copy = () => {
    if (wStore.curAccount && mStore.memberInfo.uid > 0) {
        const value = process.env.VUE_APP_BASE_URL + '/#/?invite=' + wStore.curAccount
        const input = document.createElement('input')
        input.value = value
        input.id = 'creatDom'
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
        ElMessage({
            type: 'success',
            message: i18n.global.t('alert.copied')
        })
    }
}

const copyAddress = (value) => {
    if (value) {
        const input = document.createElement('input')
        input.value = value
        input.id = 'creatDom'
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
        ElMessage({
            type: 'success',
            message: i18n.global.t('alert.copied')
        })
    }
}



const goPage = (url) => {
    router.push({
        path: url
    })
}

const addressList = reactive([
    '0xasdwqe', '0x123123', '0x23123', '0x123dsjkd '
])

// 获取等级图标
const getGrade = (grade, uid) => {
    let str = 'normal'
    if (!uid || uid - 0 <= 0) {
        return str
    } else if (grade == 0) {
        str = 'vip'
    } else if (grade == 6) {
        str = 'max'
    } else {
        str = 't' + grade
    }
    return str
}

function getInfo() {
    if (wbStore.isLogin()) {
        queryInfo().then(result => {
            if (Util.isDefine(result) && result.data.code === 1) {
                const data = result.data.data
                info.grade = data.grade - 0
                info.regTime = data.bindTime - 0
                info.directMember = data.shareTotal - 0
                info.teamMember = data.teamTotal - 0
                info.teamStake = data.teamStakeTotal - 0
            }
        })
    }
}

function getSubTeamList(address) {
    if (!wbStore.isLogin()||!address) {
        return
    }
    showAddressMore.value = false
    teams.list = []
    teams.page = 1
    teams.pageSize = 0
    teams.totalPage = 0
    teams.totalRecord = 0

    teams.curAddress = address

    getTeams()
}

function getTeamList() {
    if (!wbStore.isLogin()) {
        return
    }

    teams.list = []
    teams.page = 1
    teams.pageSize = 0
    teams.totalPage = 0
    teams.totalRecord = 0

    teams.curAddress = ''

    getTeams()
}
function getNextTeamList() {
    if (!wbStore.isLogin()) {
        return
    }

    if (teams.page < teams.totalPage) {
        teams.page++
    } else {
        return
    }

    getTeams()
}

function getTeams() {
    if (!wbStore.isLogin()) {
        return
    }

    let subAddress = teams.curAddress

    queryTeamList({ page: teams.page, pageSize: 6, account: subAddress }).then(function (result) {
        if (Util.isDefine(result) && result.data.code === 1) {
            const data = result.data.data.pagingList
            teams.page = data.currPage
            teams.pageSize = data.pageSize
            teams.totalPage = data.totalPage
            teams.totalRecord = data.totalRecord

            //判断是否展示省略号
            const state = result.data.data.state;

            const addrList = result.data.data.addressList
            teams.addressList = []
            const length = Util.isDefine(addrList) ? addrList.length : 0
            
            //以下为之前的代码
            // for (let i = length - 1; i >= 0; i--) {
            //     if (addrList[i]) {
            //         teams.addressList.push({
            //             address: addrList[i],
            //             showAddress: '...' + addrList[i].substring(38)
            //         })
            //     } else {
            //         teams.addressList.push({
            //             address: '',
            //             showAddress: '...'
            //         })
            //     }
            // }

          console.log(length)

            if(length){
                let i = length;
                while( i > 0 ){
                    i--
                    teams.addressList.push({
                        address: addrList[i],
                        showAddress: '...' + addrList[i].substring(38)
                    })
                }
            }else{
                teams.addressList = []
            }

            // 如果需要展示省略号，在数组里面下标为2的位置添加一个空的对象，html会自动处理
            if(state){
                let obj = {
                    address: '',
                    showAddress: '...'
                }
                teams.addressList.splice(2,0,obj)
            }

            //以上是更新后的代码

            const list = data.resultList

            for (let i = 0; Util.isDefine(list) && i < list.length; i++) {
                const a = list[i]
                const member = {}
                member.address = a.address
                member.uid = a.uid
                member.showAddress = a.address.substring(0, 10) + '...' + a.address.substring(36)
                member.regTime = Util.showTime(a.bindTime)
                member.grade = a.grade
                member.share = Util.numFormatBase(a.shareTotal)
                member.team = Util.numFormatBase(a.teamTotal)
                member.stakeRemainTotal = Util.numFormat4Floor(a.stakeRemainTotal - 0)
                member.teamStakeTotal = Util.numFormat4Floor(a.teamStakeTotal - 0)

                teams.list.push(member)
            }
        }
    })
}

const showAddressMore = ref(false);

onMounted(() => {
    getInfo()
    getTeamList()
})

const curAccount = computed(() => {
    return wStore.curAccount
})
watch(curAccount, (newVal, oldVal) => {
    if (newVal) {
        if (oldVal) {
            alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
            wbStore.setLoginState(true)
            mStore.queryMemberInfo()

            getInfo()
            getTeamList()

        }).catch((reason) => {
            wbStore.setLoginState(false)
            console.error('login failed')
        })
    }
})
</script>
<style scoped src="./css/usernew.css"></style>